import { AnalyticsActionType } from '@wpp-open/core'
import React, { FormEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAssistant } from 'hooks/useAssistant'
import { useChatContext } from 'hooks/useChatContext'
import { useMentions } from 'hooks/useMentions'
import { ConversationMessageDto, FeedbackDto, QuestionDto } from 'types/dto/ConversationDto'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import ChatDisplay from './ChatDisplay'
import ChatQuestionInput from './ChatQuestionInput'
import { filterMentions } from './mentions/utils/mentions'
import { parseMessageToString } from './utils/utils'
import { charLength } from '../../../constants/ui'

const NEW_CONVERSATION_FLAG = 'newConversationFlag'
const QUESTION_MAX_LENGTH = charLength.QUESTION_MAX_LENGTH

export const Chat = () => {
  const { conversation, updateConversation, searchString, tab } = useChatContext()

  const { mentionOptions, findMentionUsed } = useMentions()

  const inputRef = useRef<HTMLDivElement>(null)
  const [question, setQuestion] = useState('')
  const [questionCharCount, setQuestionCharCount] = useState(0)
  const [firstQuestion, setFirstQuestion] = useState('')
  const [conversationIdOfLoadingAnswer, setConversationIdOfLoadingAnswer] = useState<string | null>(null)
  const [showTokenLimitReached, setShowTokenLimitReached] = useState(false)
  const [answerError, setAnswerError] = useState(false)
  const [messageId, setMessageId] = useState<string | undefined>(undefined)
  const defaultValue = useRef('')

  const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false)
  const [activeMentionId, setActiveMentionId] = useState('')
  const [mentionOptionsFiltered, setMentionOptionsFiltered] = useState(mentionOptions)

  const { startConversation, askQuestion, activeConversationMessages, isFetchingConversationMessages } = useAssistant({
    selectedConversationId: conversation?.id,
  })

  useEffect(() => {
    setQuestion('')
    setQuestionCharCount(0)
  }, [tab])

  const scrollToBottom = useCallback(
    (id?: string) => {
      if (id) {
        const scrollImmediateTimeout = setTimeout(() => {
          if (conversation) {
            refBubbles.current[id]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            })
          }
        }, 10)

        return () => {
          clearTimeout(scrollImmediateTimeout)
        }
      } else {
        const scrollImmediateTimeout = setTimeout(() => {
          if (conversation) {
            refScrollBottomDiv.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            })
          }
        }, 10)

        return () => {
          clearTimeout(scrollImmediateTimeout)
        }
      }
    },
    [conversation],
  )

  const handleMentionClick = (mention: string) => {
    const inputField = inputRef.current

    if (inputField !== null) {
      const clickedMention = `@${mention}`
      const atIndex = question.lastIndexOf('@')
      let inputValue = question.substring(0, atIndex) + clickedMention

      inputValue = replaceMentionsWithSpan(inputValue)
      inputField.innerHTML = inputValue.concat(' ')

      const inputValueWithoutHtml = inputField.innerHTML.replace(/<\/?[^>]+(>|$)/g, '')

      setQuestion(inputValueWithoutHtml)
      setQuestionCharCount(inputValueWithoutHtml.length)
      setMentionDropdownVisible(false)

      inputField.focus()
      setTimeout(() => {
        setCursorToEnd(inputField)
      }, 0)
    }
  }

  useEffect(() => {
    // console.log('how many times does this run?')
    updateConversation(draft => {
      if (!draft) return
      draft.messages = activeConversationMessages
    })
    if (activeConversationMessages.length > 0) {
      setFirstQuestion('')
    }
  }, [activeConversationMessages, updateConversation])

  const refScrollBottomDiv = useRef<HTMLDivElement>(null)

  const answerIsLoading = useMemo(
    () =>
      conversationIdOfLoadingAnswer === conversation?.id ||
      conversationIdOfLoadingAnswer === NEW_CONVERSATION_FLAG ||
      firstQuestion.length > 0,
    [conversationIdOfLoadingAnswer, conversation, firstQuestion],
  )

  const refBubbles = useRef({} as any)

  useEffect(() => {
    if (!isFetchingConversationMessages) {
      scrollToBottom()
    }
  }, [isFetchingConversationMessages, scrollToBottom])

  useEffect(() => {
    if (!conversation?.messages?.length || isFetchingConversationMessages) return
    if (searchString) {
      const lastSearchMatchId = conversation.messages
        .slice()
        .reverse()
        .find(message => parseMessageToString(message).toLowerCase().includes(searchString.toLowerCase()))?.id
      if (lastSearchMatchId) {
        scrollToBottom(lastSearchMatchId)
      } else {
        scrollToBottom()
      }
    } else if (messageId) {
      scrollToBottom(messageId)
    } else {
      scrollToBottom()
    }
  }, [searchString, scrollToBottom, conversation?.messages, messageId, isFetchingConversationMessages])

  /**
   * Handles the click of a prompt
   * @param prompt
   */
  const handlePromptClick = (prompt: string) => {
    const formattedPrompt = prompt.replace(/\[/g, '<mark>').replace(/]/g, '</mark>')

    inputRef?.current?.innerHTML !== undefined
      ? (inputRef.current.innerHTML = formattedPrompt)
      : (defaultValue.current = formattedPrompt)

    const promptWithoutHtml = formattedPrompt.replace(/<\/?[^>]+(>|$)/g, '')

    setQuestion(promptWithoutHtml)
    setQuestionCharCount(promptWithoutHtml.length)
  }
  /**
   * Handles the input of the question
   */
  const handleInput = () => {
    let inputRefValue = inputRef?.current?.innerText ?? ''
    const findNewLine = inputRefValue.includes('\n')

    if (findNewLine && inputRefValue.length === 1 && inputRef?.current?.innerHTML !== undefined) {
      inputRefValue = ''
      inputRef.current.innerHTML = ''
    }

    setQuestion(inputRefValue)
    setQuestionCharCount(inputRefValue.length)
  }

  const removeQuestionsFromMessages = (messages: ConversationMessageDto[]) => {
    return messages.filter((message: any) => message.type !== 'PROMPT')
  }

  const onQuestionKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (mentionDropdownVisible) {
        const selectedMention = mentionOptions.find(el => el.id === activeMentionId)

        if (selectedMention) {
          handleMentionClick(selectedMention.display)
        }

        setMentionDropdownVisible(false)
        return
      }

      setMentionDropdownVisible(false)
      handleSubmitQuestion()
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (mentionDropdownVisible) {
        event.preventDefault()

        const indexOfSelectedMention = mentionOptionsFiltered.findIndex(el => el.id === activeMentionId)

        if (indexOfSelectedMention > -1) {
          if (event.key === 'ArrowUp') {
            setActiveMentionId(
              mentionOptionsFiltered[
                indexOfSelectedMention === 0 ? mentionOptionsFiltered.length - 1 : indexOfSelectedMention - 1
              ].id,
            )
          } else if (event.key === 'ArrowDown') {
            setActiveMentionId(
              mentionOptionsFiltered[
                indexOfSelectedMention === mentionOptionsFiltered.length - 1 ? 0 : indexOfSelectedMention + 1
              ].id,
            )
          }
        }
        return
      }
    }

    if (event.key === '@') {
      setMentionDropdownVisible(true)
    }

    if (event.key === ' ') {
      setMentionDropdownVisible(false)
    }

    if (event.key === 'Backspace') {
      const inputField = inputRef.current

      if (inputField?.innerText?.length === 0) {
        setMentionDropdownVisible(false)
        return
      }
      const lastAtIndex = question.lastIndexOf('@')
      const mentionMatches = question.match(/@\w+/g)

      if (mentionMatches && mentionMatches.length > 0) {
        const lastMention = mentionMatches[mentionMatches.length - 1]

        if (lastMention && question.endsWith(lastMention)) {
          setMentionDropdownVisible(true)
        }
      }

      if (lastAtIndex === Number(questionCharCount) - 1) {
        setMentionDropdownVisible(false)
      }
    }
  }
  /**
   * Handles the mouse down event
   * @param event
   */
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  function replaceMentionsWithSpan(input: string) {
    const mentionMatches = input.match(/@\w+/g)

    if (mentionMatches && mentionMatches.length > 0) {
      mentionMatches.forEach(mention => {
        const replacement = `<span>${mention}</span>`
        input = input.replaceAll(mention, replacement)
      })
    }

    return input
  }

  const setCursorToEnd = (element: HTMLElement) => {
    const range = document.createRange()
    const selection = window.getSelection()
    range.setStart(element, element.childNodes.length)
    range.collapse(true)
    selection?.removeAllRanges()
    selection?.addRange(range)
  }

  const onSubmitQuestion = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleSubmitQuestion()
  }

  const handleSubmitQuestion = async () => {
    const questionWithoutHtml = question.replace(/<\/?[^>]+(>|$)/g, '')
    const formattedQuestion = questionWithoutHtml.replace(/\*/g, '')
    if (!formattedQuestion.trim() || formattedQuestion.length > QUESTION_MAX_LENGTH) return

    if (inputRef?.current?.innerHTML !== undefined) {
      inputRef.current.innerHTML = ''
    }

    setConversationIdOfLoadingAnswer(conversation?.id ? conversation.id : NEW_CONVERSATION_FLAG)
    const questionCopy = formattedQuestion
    setQuestion('')
    setQuestionCharCount(0)
    setAnswerError(false)
    setMessageId(undefined)
    try {
      if (!conversation) {
        setFirstQuestion(questionCopy)

        const mentionUsed = findMentionUsed(questionCopy)
        trackAnalytics({
          type: AnalyticsActionType.action,
          payload: {
            action: EVENTS.ACTIONS.MESSAGE_SENT,
            params: [
              { key: 'newConversation', value: 'true' },
              { key: 'mentionUse', value: mentionUsed ? 'true' : 'false' },
              { key: 'mention', value: mentionUsed ? `@${mentionUsed.id}` : '' },
            ],
          },
        })
        const newConversation = await startConversation({ question: questionCopy })
        if (typeof newConversation === 'number') {
          if (newConversation === 429) {
            setShowTokenLimitReached(true)
          }
          return
        }
        if (newConversation) {
          updateConversation(newConversation)
        }
        return
      }
      const questionMessage = {
        content: questionCopy,
        role: 'user',
        type: 'PROMPT',
        chatId: conversation.id,
      } as QuestionDto

      updateConversation(conversationDraft => {
        if (!conversationDraft) return
        conversationDraft.messages.push(questionMessage)
      })

      const mentionUsed = findMentionUsed(questionCopy)
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: {
          action: EVENTS.ACTIONS.MESSAGE_SENT,
          params: [
            { key: 'newConversation', value: 'false' },
            { key: 'mentionUse', value: mentionUsed ? 'true' : 'false' },
            { key: 'mention', value: mentionUsed ? `@${mentionUsed.id}` : '' },
          ],
        },
      })

      const answerResponse = await askQuestion({ conversationId: conversation.id, question: questionCopy })
      if (typeof answerResponse === 'number') {
        if (answerResponse === 429) {
          setShowTokenLimitReached(true)
        }
        setAnswerError(true)
        return
      }
      if (answerResponse) {
        updateConversation(conversationDraft => {
          if (!conversationDraft) return

          const answers = Array.isArray(answerResponse) ? removeQuestionsFromMessages(answerResponse) : [answerResponse]
          conversationDraft.messages.push(...answers)
          scrollToBottom()
        })
      }
    } catch (err) {
      console.error(err)
    } finally {
      setConversationIdOfLoadingAnswer(null)
    }
  }

  const onFeedbackUpdate = (feedback: FeedbackDto) => {
    setMessageId(feedback.messageId)
    updateConversation(draft => {
      if (!draft) return
      draft.messages = draft.messages.map(item => (item.id === feedback.messageId ? { ...item, feedback } : item))
    })
  }

  useEffect(() => {
    if (mentionDropdownVisible) {
      const mention = question.lastIndexOf('@') !== -1 ? question.slice(question.lastIndexOf('@')) : ''
      const filteredMentions = filterMentions(mention, mentionOptions)

      setMentionOptionsFiltered(filteredMentions)
      if (filteredMentions.length) {
        setActiveMentionId(filteredMentions[0].id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, mentionDropdownVisible])

  return (
    <div>
      <ChatDisplay
        question={question}
        isFetchingConversationMessages={isFetchingConversationMessages}
        firstQuestion={firstQuestion}
        conversationMessages={conversation?.messages || activeConversationMessages}
        answerIsLoading={answerIsLoading}
        answerError={answerError}
        handlePromptClick={handlePromptClick}
        scrollToBottom={scrollToBottom}
        onFeedbackUpdate={onFeedbackUpdate}
        showTokenLimitReached={showTokenLimitReached}
        refScrollBottomDiv={refScrollBottomDiv}
        refBubbles={refBubbles}
      />
      <ChatQuestionInput
        inputRef={inputRef}
        question={question}
        questionCharCount={questionCharCount}
        mentionOptionsMemo={mentionOptionsFiltered}
        onSubmitQuestion={onSubmitQuestion}
        onQuestionKeyDown={onQuestionKeyDown}
        answerIsLoading={answerIsLoading}
        onInput={handleInput}
        onMouseDown={handleMouseDown}
        mentionDropdownVisible={mentionDropdownVisible}
        mentionClicked={handleMentionClick}
        defaultValue={defaultValue.current}
        selectedMentionId={activeMentionId}
        enableEdit={!answerIsLoading}
      />
    </div>
  )
}
