import { WppActionButton, WppSpinner, WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/savedConversationsList/savedConversationsListItem/SavedConversationsListItem.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { PortalModal } from 'components/common/portalModal/PortalModal'
import { WarningConfirmationModal } from 'components/common/warningConfirmModal/WarningConfirmationModal'
import { ListItemActions } from 'constants/ui'
import { useAssistant } from 'hooks/useAssistant'
import { useToast } from 'hooks/useToast'
import { ConversationDto } from 'types/dto/ConversationDto'
import { formatDateByTime } from 'utils/dateFormat'

import { SavedConversationsListItemMenu } from './savedConversationListItemMenu/SavedConversationListItemMenu'

interface Props {
  conversation: ConversationDto
  onSelectConversation: (conversation: ConversationDto) => void
  onDeleteConversation: (conversation: ConversationDto) => void
}

export const SavedConversationsListItem = ({ conversation, onSelectConversation, onDeleteConversation }: Props) => {
  const { t } = useTranslation()
  const { deleteConversation } = useAssistant()
  const { osContext } = useOs()

  const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] = useState(false)
  const [isShareConversationModalOpen, setIsShareConversationModalOpen] = useState(false)

  const [isloading, setIsLoading] = useState(false)

  const { showToast } = useToast()

  const { patchConversation } = useAssistant()

  const handleConversationClick = () => {
    onSelectConversation(conversation)
  }

  const lastMessageDateString = conversation.latestInteraction || conversation.createdAt
  const lastMessageDateStringFormated = useMemo(() => {
    return formatDateByTime(lastMessageDateString, osContext.userDetails.dateLocale)
  }, [lastMessageDateString, osContext.userDetails.dateLocale])

  const handleConfirmDeleteConversation = () => {
    deleteConversation({ conversationId: conversation.id })
      .then(() => {
        showToast({
          message: 'Conversation deleted',
          type: 'success',
          duration: 4000,
        })
        onDeleteConversation(conversation)
      })
      .catch(() => {
        showToast({
          message: 'Conversation deleted',
          type: 'error',
          duration: 4000,
        })
      })
    setIsDeleteConversationModalOpen(false)
  }

  const handleConfirmShareConversation = (user?: string) => {
    console.log('share conversation: ', user)
    setIsShareConversationModalOpen(false)
  }

  const onSelectAction = (action: string) => {
    if (action === ListItemActions.DELETE) {
      setIsDeleteConversationModalOpen(true)
    } else if (action === ListItemActions.SHARE) {
      setIsShareConversationModalOpen(true)
    } else if (action === ListItemActions.PIN) {
      setIsLoading(true)
      patchConversation({ conversationId: conversation.id, pinned: !conversation.pinned }).then(() => {
        setIsLoading(false)
      })
    }
  }

  return (
    <>
      <WppTooltip className={clsx(styles.conversationItemTooltip, 'cancel-drag')} text={lastMessageDateStringFormated}>
        <button className={clsx(styles.conversationItemButton)} onClick={handleConversationClick}>
          <Flex className={styles.flexBaseWidth} direction="row" justify="between" gap={8}>
            <WppTypography tag="span" type="s-body" className={styles.conversationItemText}>
              {conversation.name}
            </WppTypography>

            {isloading ? (
              <WppActionButton>
                <WppSpinner className={styles.spinner} />
              </WppActionButton>
            ) : (
              <SavedConversationsListItemMenu
                pinned={conversation.pinned}
                onSelect={onSelectAction}
                onClick={e => {
                  e.stopPropagation()
                }}
              />
            )}
          </Flex>
        </button>
      </WppTooltip>

      <PortalModal>
        <WarningConfirmationModal
          open={isDeleteConversationModalOpen}
          title={t('delete_conversation_alert.title')}
          bodyText={t('delete_conversation_alert.description')}
          onClose={() => setIsDeleteConversationModalOpen(false)}
          onConfirm={handleConfirmDeleteConversation}
        />
      </PortalModal>

      <WarningConfirmationModal
        open={isShareConversationModalOpen}
        title={t('share_conversation.title')}
        bodyText={t('share_conversation.placeholder')}
        isShareModal
        onClose={() => setIsShareConversationModalOpen(false)}
        onConfirm={handleConfirmShareConversation}
      />
    </>
  )
}
