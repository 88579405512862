import {
  WppActionButton,
  WppIconComment,
  WppTypography,
  WppIconClose,
  WppIconChevron,
  WppToastContainer,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { memo, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Rnd } from 'react-rnd'

import { AssistantContextMenu } from 'components/assistant/assistantContextMenu/AssistantContextMenu'
import { AssistantContent } from 'components/assistant/assistantPopover/assistantContent/AssistantContent'
import styles from 'components/assistant/assistantPopover/AssistantPopover.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'
import { useAssistant } from 'hooks/useAssistant'
import { ConversationDto } from 'types/dto/ConversationDto'
import { UserSettingsDto } from 'types/dto/UserSettingsDto'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import { AssistantSizes } from './assistantUserSettings/AssistantUserSettings'

const SettingsLsKeys = {
  SIZE: 'assistantSize',
  POSITION: 'assistantPosition',
}

const AssistantHeaderTitles: Record<AssistantTabs, string> = {
  [AssistantTabs.CHAT]: 'ai_assistant',
  [AssistantTabs.HISTORY]: 'history',
  [AssistantTabs.SETTINGS]: 'common.settings',
}

const AssistantSizesValues: Record<AssistantSizes, { width: string; height: string }> = {
  [AssistantSizes.S]: {
    width: '440',
    height: '600',
  },
  [AssistantSizes.M]: {
    width: '500',
    height: '788',
  },
  [AssistantSizes.L]: {
    width: '540',
    height: '900',
  },
}

export const AssistantPopover = memo(() => {
  const { t } = useTranslation()

  const { userSettingsAPI, patchUserSettings } = useAssistant({ enableSettings: true })

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const [assistantPosition, setAssistantPosition] = useState({ x: 24, y: 24 })
  const [assistantSize, setAssistantSize] = useState(
    AssistantSizesValues[userSettingsAPI.windowSize || AssistantSizes.S],
  )

  const [selectedTab, setSelectedTab] = useState<AssistantTabs>(AssistantTabs.CHAT)
  const [selectedConversationHistory, setSelectedConversationHistory] = useState<ConversationDto | undefined>(undefined)
  const [closeHistoryConversation, setCloseHistoryConversation] = useState(false)

  const [clearConversation, setClearConversation] = useState(false)

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    if (
      windowSize.height < parseInt(assistantSize.height) &&
      AssistantSizesValues[AssistantSizes.S].height !== assistantSize.height
    ) {
      setAssistantSize(AssistantSizesValues[AssistantSizes.S])
      if (userSettingsAPI.id) {
        patchUserSettings({
          settingsId: userSettingsAPI.id,
          windowSize: AssistantSizes.S,
          mentionsEnabled: userSettingsAPI.mentionsEnabled,
        })
      }
    }
    if (
      assistantPosition.x > windowSize.width - parseInt(assistantSize.width) ||
      assistantPosition.y > windowSize.height - parseInt(assistantSize.height)
    ) {
      if (assistantPosition.x === 24 && assistantPosition.y === 24) return
      setAssistantPosition({ x: 24, y: 24 })
      localStorage.setItem(SettingsLsKeys.POSITION, JSON.stringify(assistantPosition))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.height, windowSize.width, assistantSize.width, assistantSize.height, assistantPosition])

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // On mounted
  useEffect(() => {
    const lsDataSize = localStorage.getItem(SettingsLsKeys.SIZE)
    if (lsDataSize) {
      const assistantSizeParsed = JSON.parse(lsDataSize)
      setAssistantSize(assistantSizeParsed)
    }

    const lsDataPosition = localStorage.getItem(SettingsLsKeys.POSITION)
    if (lsDataPosition) {
      const assistantPositionParsed = JSON.parse(lsDataPosition)
      setAssistantPosition(assistantPositionParsed)
    }
  }, [])

  const onSelectTab = (tab: AssistantTabs) => {
    if (tab === AssistantTabs.CHAT) {
      setClearConversation(true)
      setTimeout(() => setClearConversation(false), 1)
    }

    if (tab === AssistantTabs.HISTORY && selectedTab !== AssistantTabs.HISTORY) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.HISTORY_SCREEN,
      })
    }

    if (tab === AssistantTabs.SETTINGS && selectedTab !== AssistantTabs.SETTINGS) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.SETTINGS_SCREEN,
      })
    }

    setSelectedTab(tab)
  }

  const onSelectConversationHistory = (conversation: ConversationDto | undefined) => {
    setSelectedConversationHistory(conversation)
  }

  const handleBackClick = () => {
    if (!selectedConversationHistory) {
      setSelectedTab(AssistantTabs.CHAT)
    } else {
      if (selectedTab === AssistantTabs.HISTORY) {
        setCloseHistoryConversation(true)
        setSelectedConversationHistory(undefined)
        setTimeout(() => setCloseHistoryConversation(false), 1)
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: EVENTS.SCREENS.HISTORY_SCREEN,
        })
      } else {
        setSelectedTab(AssistantTabs.HISTORY)
      }
    }
  }

  const toggleIsPopoverOpen = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen)

    if (isOpen) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.WELCOME_SCREEN,
      })
    }
    if (!isOpen) {
      setSelectedTab(AssistantTabs.CHAT)
      setSelectedConversationHistory(undefined)
      setCloseHistoryConversation(false)
    }
  }

  const onUpdateUserSettings = (userSettingsParam: UserSettingsDto) => {
    if (userSettingsParam.windowSize !== userSettingsAPI.windowSize) {
      setAssistantSize(AssistantSizesValues[userSettingsParam.windowSize])
      localStorage.setItem(SettingsLsKeys.SIZE, JSON.stringify(AssistantSizesValues[userSettingsParam.windowSize]))
    }

    patchUserSettings({
      settingsId: userSettingsParam.id,
      windowSize: userSettingsParam.windowSize,
      mentionsEnabled: userSettingsParam.mentionsEnabled,
    })
  }

  return (
    <>
      <div id="modal-container" />
      <WppToastContainer id="toast-container" maxToastsToDisplay={5} />
      <WppActionButton onClick={() => toggleIsPopoverOpen(true)}>
        <WppIconComment />
      </WppActionButton>
      {isPopoverOpen && (
        <div className={styles.dragSurface}>
          <Rnd
            minWidth={440}
            minHeight={600}
            maxWidth={900}
            maxHeight="90dvh"
            bounds="parent"
            position={{ x: assistantPosition.x, y: assistantPosition.y }}
            size={{ width: assistantSize.width, height: assistantSize.height }}
            enableUserSelectHack={false}
            onDragStop={(e, d) => {
              setAssistantPosition({ x: d.x, y: d.y })
              localStorage.setItem(SettingsLsKeys.POSITION, JSON.stringify(assistantPosition))
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setAssistantSize({
                width: ref.style.width,
                height: ref.style.height,
              })
              localStorage.setItem(
                SettingsLsKeys.SIZE,
                JSON.stringify({
                  width: ref.style.width,
                  height: ref.style.height,
                }),
              )
              setAssistantPosition({ x: position.x, y: position.y })
              localStorage.setItem(
                SettingsLsKeys.POSITION,
                JSON.stringify({
                  x: position.x,
                  y: position.y,
                }),
              )
            }}
            cancel=".cancel-drag"
            className={styles.assistantRnd}
          >
            <div className={styles.assistant}>
              <Flex className={styles.header} justify="between" align="center" gap={8}>
                <Flex align="center" gap={8} className={styles.flexBaseWidth}>
                  {selectedTab !== AssistantTabs.CHAT && (
                    <WppActionButton onClick={handleBackClick} className="cancel-drag">
                      <WppIconChevron direction="left" />
                    </WppActionButton>
                  )}

                  <WppTypography type="xl-heading" className={clsx(styles.title, styles.maxWHeader)}>
                    {selectedConversationHistory && selectedTab === AssistantTabs.HISTORY
                      ? selectedConversationHistory.name
                      : t(AssistantHeaderTitles[selectedTab])}
                  </WppTypography>
                </Flex>
                <Flex align="center" gap={2}>
                  <AssistantContextMenu onSelect={onSelectTab} />
                  <WppActionButton onClick={() => toggleIsPopoverOpen(false)} className="cancel-drag">
                    <WppIconClose color="var(--wpp-grey-color-800)" />
                  </WppActionButton>
                </Flex>
              </Flex>

              <AssistantContent
                selectedTabOuter={selectedTab}
                onSelectTabOuter={onSelectTab}
                onSelectConversation={onSelectConversationHistory}
                closeHistoryConversation={closeHistoryConversation}
                clearConversation={clearConversation}
                onUpdateUserSettings={onUpdateUserSettings}
              />
            </div>
          </Rnd>
        </div>
      )}
    </>
  )
})
