import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { ConversationDto } from 'types/dto/ConversationDto'

export interface Props {
  tenantId: string
  userId: string
  messages: string[]
  tenantUrl: string
}

export const createConversation = ({
  tenantId,
  userId,
  messages,
  tenantUrl,
}: Props): CancelableRequestProducer<ConversationDto> => {
  return assistantApi.post('/chats', { tenantId, userId, messages, tenantUrl })
}
