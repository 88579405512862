// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QvEiT{margin-bottom:12px;padding:16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.gxQgl{width:100%;max-width:360px;height:100%;margin:0 auto}.VBlL9{width:100%;margin-bottom:8px;padding:12px 16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.fsXA0{width:100%;white-space:pre-line}.LC9ZE{min-width:32px;height:32px;border-radius:100%;background:var(--wpp-primary-color-400);display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/answerLoadingPlaceholder/AnswerLoadingPlaceholder.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,eAAA,CACA,WAAA,CACA,aAAA,CAGF,OACE,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,oBAAA,CAGF,OACE,cAAA,CACA,WAAA,CACA,kBAAA,CACA,uCAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".card {\n  margin-bottom: 12px;\n  padding: 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.illustration {\n  width: 100%;\n  max-width: 360px;\n  height: 100%;\n  margin: 0 auto;\n}\n\n.chatBubbleAnswer {\n  width: 100%;\n  margin-bottom: 8px;\n  padding: 12px 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.text {\n  width: 100%;\n  white-space: pre-line;\n}\n\n.iconCircle {\n  min-width: 32px;\n  height: 32px;\n  border-radius: 100%;\n  background: var(--wpp-primary-color-400);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "QvEiT",
	"illustration": "gxQgl",
	"chatBubbleAnswer": "VBlL9",
	"text": "fsXA0",
	"iconCircle": "LC9ZE"
};
export default ___CSS_LOADER_EXPORT___;
