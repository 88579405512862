export const EVENTS = {
  SCREENS: {
    WELCOME_SCREEN: 'aia_pv_welcome_screen',
    HISTORY_SCREEN: 'aia_pv_history_screen',
    SETTINGS_SCREEN: 'aia_pv_settings_screen',
  },
  ACTIONS: {
    MESSAGE_SENT: 'aia_act_message_sent',
    RESPONSE_COPIED: 'aia_act_response_copied',
    RESPONSE_RATED: 'aia_act_response_rated',
    FEEDBACK_SUBMITTED: 'aia_act_feedback_submitted',
  },
}

export const getEventPayload = (event: string, itemName: string) => event.replace('{itemName}', itemName)
