export interface PromptsInterface {
  value: string
  label: string
  prompt: string
  tooltip?: string
}

export const PromptsData = [
  {
    value: 'campaign-ideas',
    label: 'Creative campaign ideas',
    prompt:
      'Creative campaign ideas for [a new EV truck available for purchase] in [France]. The target audiences are [young creatives and people working in Tech] who earn [below-average income]. These young people are [interested in trends, EVs and technology in general], and they are [the first to try out new things on the market].',
  },
  {
    value: 'herp-story',
    label: 'Create brand hero story',
    prompt:
      'Create a brand hero story for [DS 21 Citroen] for the [upcoming Superbowl awareness video campaign] in [the US]. [Depict the history and hard work behind the inception of that car, its marvellous design like it is still being produced today].',
  },
  {
    value: 'gen-ideas',
    label: 'Generate ideas',
    prompt:
      'Generate social media post ideas for [Sense8 - the Netflix show] for [Spain in Spanish]. [Max 20 words]. Use the quotes from that TV show.',
  },
  {
    value: 'gen-compet-ideas',
    label: 'Generate competition ideas',
    prompt:
      "Generate ideas [for the social media contest] for [Dyson] related to [the anniversary of their first cyclone vacuum cleaner ever produced]. [Use the vocabulary used by Gen Z in the US, along with slang and social media jokes. Don't exceed 300 words.]",
  },
  {
    value: 'gen-brief',
    label: 'Generate brief',
    prompt:
      'Generate brief for [Shiseido] trying to [increase their market share] in [California] and [suggest marketing channels and weight between channels in terms of spending].',
  },

  {
    value: 'improve-text',
    label: 'Improve text',
    prompt:
      'Rewrite text: ["Never stop testing, and your advertising will never stop improving."]. Make it sound [casual and inspiring to be used in the pitch deck using Neil Gaiman\'s style]. Max 100 words.',
  },
  {
    value: 'find-data',
    label: 'Find data',
    prompt:
      'Find data about [consumer drones]. Use [2020] data list [sales numbers by country and brand], [sum up the statistics and list up the most popular models].',
  },
  {
    value: 'desc-views',
    label: "Describe Gen Z's views",
    prompt: 'Describe [Gen Z] [views] on [eco living, investing & the HIRE movement].',
  },
  {
    value: 'improve-desc',
    label: 'Improve product description',
    prompt:
      'Improve the product description: [the Chrysler Turbine Car is an experimental two-door hardtop coupe powered by a turbine engine and manufactured by Chrysler from 1963 to 1964. The bodywork was constructed by Italian design studio Carrozzeria Ghia and Chrysler completed the final assembly in Detroit] to sound confident, young and futuristic. Use the popular slang and pop-culture vocabulary for this. 100 words max.',
  },
]

export const PromptsDataMentions = [
  {
    value: 'data',
    label: '@data',
    prompt:
      '@data give me [data sources] to help me understand my [audience] among [health-focused & eco-conscious pet owners and breeders] in [the US].',
    tooltip: 'Get quick insights into data',
  },
  {
    value: 'brand',
    label: '@brand',
    prompt: '@brand [Coca-Cola]',
    tooltip: 'Get insights into brands',
  },
  {
    value: 'appsuggestion',
    label: '@appsuggestion',
    prompt: '@appsuggestion I need to [generate and image] for my social media post',
    tooltip: 'Find an app for your needs',
  },
  {
    value: 'campaign',
    label: '@campaign',
    prompt:
      '@campaign Nestle is [planning a new campaign] for [Gen Z] in [Italy], focusing on [cereals and breakfast nutrition].',
    tooltip: 'Get insights into campaigns',
  },
  // {
  //   label: '@LITinsights',
  //   prompt: '@LITinsights suggest insights for the top 3 segments for this activation relevant for FIFA',
  //   tooltip: 'Get LITinsights',
  // },
  // {
  //   label: '@BEACHdata',
  //   prompt:
  //     '@BEACHdata what are the potential sources of weekly+ growth in the FIFA Women`s Cup activation based on historical data',
  //   tooltip: 'Get BEACHdata',
  // },
]
