import { WppTypography, WppActionButton, WppIconSend } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { KeyboardEvent, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Mentions } from 'components/assistant/chat/mentions/Mentions'
import { QuestionInput } from 'components/assistant/chat/questionInput/QuestionInput'
import { Flex } from 'components/common/flex/Flex'
import { useChatContext } from 'hooks/useChatContext'
import { useHasPermissions } from 'hooks/useHasPermissions'

import { charLength } from '../../../constants/ui'

interface Props {
  onSubmitQuestion: (event: FormEvent<HTMLFormElement>) => void
  question: string
  answerIsLoading: boolean
  onQuestionKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void
  mentionOptionsMemo: { id: string; display: string }[]
  questionCharCount: number
  onInput: () => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  mentionDropdownVisible: boolean
  mentionClicked: (mention: string) => void
  inputRef: React.RefObject<HTMLDivElement>
  defaultValue: string
  selectedMentionId: string
  enableEdit: boolean
}

const ChatQuestionInput = ({
  onSubmitQuestion,
  question,
  answerIsLoading,
  onQuestionKeyDown,
  mentionOptionsMemo,
  questionCharCount,
  onInput,
  onMouseDown,
  mentionDropdownVisible,
  mentionClicked,
  defaultValue,
  selectedMentionId,
  inputRef,
  enableEdit,
}: Props) => {
  const { t } = useTranslation()
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { questionInputVisible } = useChatContext()
  const questionMaxLength = charLength.QUESTION_MAX_LENGTH

  if (!questionInputVisible) return null

  return (
    <form
      className={clsx(
        styles.questionInputWrapper,
        hasAccessToChatAssistantConfig && styles.questionInputWrapperFooterOffset,
      )}
      onSubmit={onSubmitQuestion}
    >
      <div className={styles.inputBorder}>
        <QuestionInput
          inputRef={inputRef}
          enableEdit={enableEdit}
          defaultValue={defaultValue}
          onInput={onInput}
          onQuestionKeyDown={onQuestionKeyDown}
          onMouseDown={onMouseDown}
        />
        {mentionOptionsMemo.length > 0 && mentionDropdownVisible && (
          <Mentions
            mentionOptions={mentionOptionsMemo}
            questionInputRef={inputRef}
            selectedMentionId={selectedMentionId}
            onClick={mentionClicked}
          />
        )}
      </div>

      <Flex justify="end" className={styles.questionCharacterLimit}>
        <WppTypography
          type="xs-body"
          className={clsx(
            question.length > questionMaxLength
              ? styles.questionCharacterLimitLabelError
              : styles.questionCharacterLimitLabel,
          )}
        >
          {t('question_input.characters_label')}:
        </WppTypography>
        <WppTypography
          type="xs-strong"
          className={clsx(question.length > questionMaxLength && styles.questionCharacterLimitLabelError)}
        >
          &nbsp;{questionCharCount}/{questionMaxLength}
        </WppTypography>
      </Flex>
      <WppActionButton
        type="submit"
        className={clsx(styles.submitQuestionButton, 'cancel-drag')}
        disabled={answerIsLoading}
      >
        <WppIconSend size="m" color="var(--wpp-grey-color-600)" />
      </WppActionButton>
    </form>
  )
}

export default ChatQuestionInput
