import {
  WppTypography,
  WppToggle,
  // WppSelect,
  // WppListItem,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useAssistant } from 'hooks/useAssistant'
import { UserSettingsDto } from 'types/dto/UserSettingsDto'

export enum DeleteHistoryPeriods {
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  ONE_YEAR = 'one_year',
}

export enum AssistantSizes {
  S = 'small',
  M = 'medium',
  L = 'large',
}

const AssistantSizesNames = {
  [AssistantSizes.S]: 'S',
  [AssistantSizes.M]: 'M',
  [AssistantSizes.L]: 'L',
}

interface Props {
  onUpdateUserSettings: (userSettings: UserSettingsDto) => void
}

export const AssistantUserSettings = ({ onUpdateUserSettings }: Props) => {
  const { t } = useTranslation()
  const { userSettingsAPI } = useAssistant()

  const [settings, setSettings] = useState(userSettingsAPI)

  const sizeMDisabled = useMemo(() => {
    return window.innerHeight < 788
  }, [])

  const sizeLDisabled = useMemo(() => {
    return window.innerHeight < 900
  }, [])

  useEffect(() => {
    setSettings(userSettingsAPI)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsAPI.windowSize])

  return (
    <>
      <Flex direction="column" gap={24} className={styles.mt16}>
        <Flex justify="between" align="center">
          <div>
            <WppTypography tag="h2" type="m-strong" className={styles.title}>
              {t('settings.default_size')}
            </WppTypography>
            <div>
              <WppTypography tag="p" type="xs-body" className={styles.text}>
                {t('settings.default_size_caption')}
              </WppTypography>
            </div>
          </div>

          <WppSegmentedControl
            className="cancel-drag"
            value={settings.windowSize}
            onWppChange={({ detail: { value } }) => {
              setSettings(prevState => ({
                ...prevState,
                windowSize: value as AssistantSizes,
              }))
              onUpdateUserSettings({ ...settings, windowSize: value as AssistantSizes })
            }}
          >
            <WppSegmentedControlItem value={AssistantSizes.S}>
              {AssistantSizesNames[AssistantSizes.S]}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={AssistantSizes.M} disabled={sizeMDisabled}>
              {AssistantSizesNames[AssistantSizes.M]}
            </WppSegmentedControlItem>
            <WppSegmentedControlItem value={AssistantSizes.L} disabled={sizeLDisabled}>
              {AssistantSizesNames[AssistantSizes.L]}
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        </Flex>

        <Flex direction="column" gap={8}>
          <WppTypography tag="h2" type="m-strong" className={styles.title}>
            {t('settings.mentions')}
          </WppTypography>
          <WppTypography tag="p" type="xs-body" className={styles.text}>
            {t('settings.mentions_caption')}
          </WppTypography>

          <WppToggle
            className={clsx(styles.toggle, 'cancel-drag')}
            checked={settings.mentionsEnabled}
            labelConfig={{ text: t('settings.mentions_action') }}
            required
            onWppChange={({ detail: { checked } }) => {
              setSettings(prevState => ({
                ...prevState,
                mentionsEnabled: checked,
              }))
              onUpdateUserSettings({ ...settings, mentionsEnabled: checked })
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}
