import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { MutableRefObject, useEffect, useRef } from 'react'

import styles from 'components/assistant/chat/mentions/Mentions.module.scss'
import { mentionDropdownPosition } from 'components/assistant/chat/mentions/utils/mentions'

export interface MentionOption {
  id: string
  display: string
}

interface MentionsProps {
  mentionOptions: MentionOption[]
  questionInputRef: MutableRefObject<any>
  selectedMentionId: string
  onClick: (mention: string) => void
}

export const Mentions = ({ mentionOptions, questionInputRef, selectedMentionId, onClick }: MentionsProps) => {
  const mentionsContainerRef = useRef<HTMLDivElement>(null)

  const handleMentionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick(event?.currentTarget.innerText)
  }

  useEffect(() => {
    mentionDropdownPosition(questionInputRef.current, mentionsContainerRef.current)
  }, [mentionOptions, questionInputRef])

  return (
    <div>
      {mentionOptions.length > 0 && (
        <div ref={mentionsContainerRef} className={styles.mentionsContainer}>
          {mentionOptions.map(option => (
            <div
              className={clsx(styles.mention, selectedMentionId === option.id && styles.active)}
              key={option.id}
              onClick={handleMentionClick}
            >
              <WppTypography tag="span" type="s-body">
                {option.display}
              </WppTypography>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
