import { WppTypography, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/answerLoadingPlaceholder/AnswerLoadingPlaceholder.module.scss'
import { Flex } from 'components/common/flex/Flex'

export const AnswerLoadingPlaceholder = () => {
  const { t } = useTranslation()

  return (
    <div>
      {/* <Flex direction="column" gap={12}>
        <BotIllustration className={styles.illustration} />
        <WppTypography type="s-strong" className={styles.text}>
          {t('answer_loading')}
        </WppTypography>
      </Flex> */}
      <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
        <div className={styles.iconCircle}>
          <WppSpinner color="var(--wpp-primary-color-100)" />
        </div>
        <WppTypography tag="p" type="s-body" className={styles.text}>
          {t('common.searching')}
        </WppTypography>
      </Flex>
    </div>
  )
}
