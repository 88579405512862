import { useCallback, useMemo } from 'react'

import { useAssistant } from './useAssistant'

const mentionOptionsList = [
  { id: 'appsuggestion', display: 'appsuggestion' },
  { id: 'campaign', display: 'campaign' },
  { id: 'brand', display: 'brand' },
  { id: 'data', display: 'data' },
]

export const useMentions = () => {
  const { userSettingsAPI } = useAssistant()

  const mentionOptions = useMemo(() => {
    return userSettingsAPI.mentionsEnabled ? mentionOptionsList : []
  }, [userSettingsAPI.mentionsEnabled])

  const findMentionUsed = useCallback(
    (prompt: string) => {
      const mentionUsed = mentionOptions.find(mention => prompt.includes(`@${mention.id}`))
      return mentionUsed ? mentionUsed : null
    },
    [mentionOptions],
  )

  return {
    mentionOptions,
    findMentionUsed,
  }
}
