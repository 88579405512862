import { ConversationMessageDto } from 'types/dto/ConversationDto'

export function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const parseMessageToString = (message: ConversationMessageDto): string => {
  var finalString = ''
  switch (message.type) {
    case 'PROMPT':
      if (message.role === 'system') return ''
      return message.content
    case 'OPEN_API':
      return message.content
    case 'STARFISH':
      finalString =
        message.content.answers?.map(answer => answer.text).join(' ') +
        ' ' +
        message.content.documents?.map(document => document.title).join(' ')
      return finalString
    case 'GENIES_CAMPAIGN':
      finalString = message.content.campaign.text + ' ' + message.content.recommendation
      const queries = message.content.sdQueries || message.content.sd_queries || []
      queries?.forEach(sdQuery => {
        finalString += ' ' + sdQuery
      })
      return finalString
    case 'GENIES_DATA':
      message.content.data.forEach(data => {
        finalString += ' ' + (data.dataWorldContent || data.data_world_content)
      })
      return finalString
    case 'GENIES_BRAND':
      return message.content
    case 'AUDIENCE':
      return message.content
    case 'GENIES_INSIGHTS':
      return message.content
  }
  return ''
}
