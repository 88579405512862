// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BwqlL{position:fixed;top:63px;left:0;width:100%;height:calc(100% - 63px);pointer-events:none}.s8wQn{pointer-events:all}.gh8nK{padding:20px 14px 0 24px;-webkit-user-select:none;user-select:none}.Ujotd{width:100%;height:100%;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-l);box-shadow:0 8px 32px 0 rgba(52,58,63,.25)}.VWHHw{white-space:nowrap}.l1VV_{max-width:90%}.eoGrv{flex:1 1;min-width:0}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/AssistantPopover.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,wBAAA,CACA,mBAAA,CAGF,OACE,kBAAA,CAGF,OACE,wBAAA,CACA,wBAAA,CAAA,gBAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,0CAAA,CACA,wCAAA,CACA,0CAAA,CAGF,OACE,kBAAA,CAGF,OACE,aAAA,CAGF,OACE,QAAA,CACA,WAAA","sourcesContent":[".dragSurface {\n  position: fixed;\n  top: 63px;\n  left: 0;\n  width: 100%;\n  height: calc(100% - 63px);\n  pointer-events: none;\n}\n\n.assistantRnd {\n  pointer-events: all;\n}\n\n.header {\n  padding: 20px 14px 0 24px;\n  user-select: none;\n}\n\n.assistant {\n  width: 100%;\n  height: 100%;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-l);\n  box-shadow: 0 8px 32px 0 rgb(52 58 63 / 25%);\n}\n\n.title {\n  white-space: nowrap;\n}\n\n.maxWHeader {\n  max-width: 90%;\n}\n\n.flexBaseWidth {\n  flex: 1;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragSurface": "BwqlL",
	"assistantRnd": "s8wQn",
	"header": "gh8nK",
	"assistant": "Ujotd",
	"title": "VWHHw",
	"maxWHeader": "l1VV_",
	"flexBaseWidth": "eoGrv"
};
export default ___CSS_LOADER_EXPORT___;
