import { WppActionButton, WppIconAdd, WppIconHistory, WppIconGear } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'

interface Props {
  onSelect: (item: AssistantTabs) => void
}

const ListItems = [
  {
    tab: AssistantTabs.CHAT,
    icon: <WppIconAdd color="var(--wpp-grey-color-800)" />,
    label: 'New Chat',
  },
  {
    tab: AssistantTabs.HISTORY,
    icon: <WppIconHistory color="var(--wpp-grey-color-800)" />,
    label: 'History',
  },
  {
    tab: AssistantTabs.SETTINGS,
    icon: <WppIconGear color="var(--wpp-grey-color-800)" />,
    label: 'Settings',
  },
]

export const AssistantContextMenu = ({ onSelect }: Props) => {
  return (
    <>
      <Flex gap={2}>
        {ListItems.map((item, idx) => (
          <WppActionButton key={idx} onClick={() => onSelect(item.tab)}>
            {item.icon && item.icon}
          </WppActionButton>
        ))}
      </Flex>
    </>
  )
}
