import { WppSpinner, WppInlineMessage } from '@platform-ui-kit/components-library-react'
import { MutableRefObject, RefObject } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useChatContext } from 'hooks/useChatContext'
import { ConversationMessageDto, FeedbackDto } from 'types/dto/ConversationDto'

import { AnswerLoadingPlaceholder } from './answerLoadingPlaceholder/AnswerLoadingPlaceholder'
import { ChatBottomText } from './chatBottomText/ChatBottomText'
import { ChatBubble } from './chatBubble/ChatBubble'
import { OpenAIAnswer } from './chatBubble/openAIAnswer/OpenAIAnswer'
import { Question } from './chatBubble/question/Question'
import { ChatInstructions } from './chatInstructions/ChatInstructions'

interface Props {
  question: string
  isFetchingConversationMessages: boolean
  firstQuestion?: string
  conversationMessages: ConversationMessageDto[]
  answerIsLoading: boolean
  answerError?: boolean
  handlePromptClick: (prompt: string) => void
  scrollToBottom: (id?: string) => void
  onFeedbackUpdate: (feedback: FeedbackDto) => void
  showTokenLimitReached: boolean
  refScrollBottomDiv: RefObject<HTMLDivElement>
  refBubbles: MutableRefObject<any>
}

const ChatDisplay = ({
  question,
  isFetchingConversationMessages,
  firstQuestion,
  conversationMessages,
  answerIsLoading,
  answerError,
  handlePromptClick,
  scrollToBottom,
  onFeedbackUpdate,
  showTokenLimitReached,
  refScrollBottomDiv,
  refBubbles,
}: Props) => {
  const { t } = useTranslation()
  const { conversation } = useChatContext()

  const isFirstContentEmpty = conversationMessages.length > 0 && conversationMessages[0].role === 'system'

  const conversationMessagesFiltered = isFirstContentEmpty ? conversationMessages.slice(1) : conversationMessages
  return (
    <div className={styles.chatWrapper}>
      {isFetchingConversationMessages && conversationMessages.length < 1 && !firstQuestion && (
        <Flex className={styles.chat} justify="center" align="center">
          <WppSpinner size="m" />
        </Flex>
      )}
      {(!isFetchingConversationMessages || firstQuestion || conversationMessages.length > 0) && (
        <div className={styles.chat}>
          {!firstQuestion && !conversation && (
            <ChatInstructions question={question} onPromptClick={handlePromptClick} />
          )}
          {firstQuestion && (
            <div className="cancel-drag">
              <Question text={firstQuestion} />
            </div>
          )}
          {conversationMessagesFiltered.map((message, index) => {
            if (message.type === 'FAILURE') return <OpenAIAnswer text={t('assisstant_errors.no_answer')} isError />
            else
              return (
                <div key={index} ref={(refBubbles.current[message.id] ??= { current: null })}>
                  <ChatBubble
                    message={message}
                    onOpenFeedback={() => scrollToBottom(message.id)}
                    onFeedbackUpdate={onFeedbackUpdate}
                  />
                </div>
              )
          })}
          {answerIsLoading && <AnswerLoadingPlaceholder />}
          {answerError && <WppInlineMessage size="s" message={t('question_input.errors.general')} type="error" />}
          <ChatBottomText tokenLimitReached={showTokenLimitReached} />
          <div ref={refScrollBottomDiv} />
        </div>
      )}
    </div>
  )
}

export default ChatDisplay
